
// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-nav {
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      font-weight: $font-weight-bold;
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-nav {
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      font-weight: $font-weight-bold;
    }
  }
}
