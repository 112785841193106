// Borders
@media (min-width: 768px) {
    .border-between-md > [class*=col-]:not(:first-child):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background: $black;
    }
}

.border-between-2 > [class*=col-]:not(:first-child):after {
    left: -1px;
    width: 2px;
}

.border-between-3 > [class*=col-]:not(:first-child):after {
    left: -2px;
    width: 3px;
}

// Brand images
.logo {
    width: 120px;
    height: 38px;
}

.footer-img {
    width: 180px;
    height: 36px;
}

// Hide "This domain is not registered with Tiny Cloud" message
.tox.tox-silver-sink.tox-tinymce-aux {
    display: none;
}