// Borders
.card-werkplek {
    background-color: $white;
    border: $card-border-width solid $white;

    .card-header {
        padding: $card-spacer-y $card-spacer-x;
        color: color-yiq($white);
        background-color: $white;
        border-bottom: $card-border-width solid $white;
    }

    .card-body {
        padding: 0 $card-spacer-x;

    }

    .card-footer {
        padding: $card-spacer-y $card-spacer-x;
        color: color-yiq($white);
        background-color: $white;
        border-top: $card-border-width solid $white;
    }


    .card-header,
    .card-body,
    .card-footer {

        > *:last-child {
            margin-bottom: 0;
        }
    }
}